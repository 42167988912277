@import "./settings/reset";
@import "./settings/fonts";
@import "./settings/mixins";
@import "./settings/variables";

html, body, .app {
  position: relative;
  min-height: 100vh;
}

body {
  color: #2e3d49;
  font-family: $basic-font;
}

h1 {
  font-family: $logo-font;
}


