.button {
  width: 100px;
  font-size: 13px;
  line-height: 35px;
  font-weight: 700;
  padding: 5px 0;
  border-radius: 20px;
  transition: 0.3s;
  border: none;
}

.red {
  color: #fff;
  background-color: #bd2130;
  &:hover {
    background-color: #7f0a15;
  }
}

.white {
  color: #000;
  background-color: #efefef;
  &:hover {
    background-color: #fff;
  }
}



.blue {
  color: #fff;
  background-color: #007bff;
  &:hover {
    background: #264d77;
  }
}