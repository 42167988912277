@import '../../../scss/settings/fonts';
@import '../../../scss/settings/mixins';

header {
  box-shadow: 0 3px 6px #00000029;
  background-color: #000;
  position: relative;
  width: 100%;
  z-index: 1000;
  .header__container.header__wrapper {
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 1rem;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header__logo {
      font-family: $logo-font;
      font-size: 2.15rem;
      a {
        color: #ff8324;
        .header__logo--accent {
          color: #f1f1f1;
        }
      }
    }
    nav {
      display: none;
      @include tablet() {
        display: block;
      }
    }
    .hamburger {
      color: #f1f1f1;
      font-size: 40px;
      cursor: pointer;
      @include tablet() {
        display: none;
      }
    }
  }
}