.diary__warning {
  position: absolute;
  top: 40%;
  left: 50%;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #000;
  max-width: 450px;
  padding: 40px 40px;
  background-color: #ffffffb8;
  border-radius: 15px;
  transform: translate(-50%, -50%);
}

.main__icons {
  i {
    margin-left: 30px;
    font-size: 35px;
  }
  .main__icons--add-training {
    color: #007bff;
  }
  .main__icons--logout {
    color: #6c757d;
  }
}

.main__buttons {
  button {
    width: 130px;
    margin-left: 15px;
  }
}

.spinner-border {
  margin-top: 200px;
}

.popUp {
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  width: 300px;
  left: calc(50% - 150px);
  top: 50%;
  transition: all 0.3s ease-out;
  h2 {
    margin-bottom: 10px;

  }
  button {
    border: none;
    margin-right: 25px;
    background-color: #fff;
    padding: 10px 10px 10px 0;
  }
}

@media (min-width: 600px) {
  .popUp {
    width: 500px;
    left: calc(50% - 250px);
  }
}
