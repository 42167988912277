.big-six__icons {
  padding-top: 40px;
  max-width: 700px;
  margin: 0 auto;
  .big-six__icons-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 95%;
    .big-six_icon {
      width: 85px;
      border: 3px solid #6c757d;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      margin: 0 5px 20px;
      img {
        width: 100%;
      }
    }
    .push-ups,
    .squats,
    .pull-ups,
    .sit-ups,
    .bridge,
    .hand-stand {
      border: 5px solid #ff8324;
    }
    .big-six_icon:hover {
      border: 5px solid #ff8324;
    }
  }
}