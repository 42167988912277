.calendar__container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 10px;
  position: relative;
  .calendar__header {
    font-size: 20px;
    margin-bottom: 5px;
    .calendar__month {
      margin-right: 15px;
    }
    .calendar__year {}
  }

  .calendar {
    width: 100%;
    border: 1px solid gray;
    .calendar__days {
      background-color: #ff8324;
      tr {
        border-bottom: 1px solid gray;
      }
      th {
        width: 14%;
        color: #fff;
        font-weight: 600;
        padding: 0 0 5px;
      }
    }

    .calendar__body {
      background-color: #fff;
      td {
        border: 2px solid #8080803d;
      }
      .stylesForToday {
        font-weight: bold;
        color: #007bff;
      }
      .focus {
        transition: 0.3s;
        background-color: #f1f1f1;
        -moz-box-shadow: inset 0px 0px 7px  rgba(0, 0, 0, 0.33);
        -webkit-box-shadow: inset 0px 0px 7px  rgba(0, 0, 0, 0.33);
        box-shadow: inset 0px 0px 7px  rgba(0, 0, 0, 0.33);
      }
      .calendar__day {
        min-height: 58px;
      }
      .calendar__training-day {
        cursor: pointer;
        i {
          color: #ffae08;
        }
        .stylesForMoreTrainings {
          color: #bd2130;
        }
        // #bd2130;
      }
      .calendar__day--notActual {
        color: #80808070;
      }
    }

  }
  .calendar__arrow {
    width: 35px;
    height: 35px;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    background-color: #80808063;
    color: #ffffffeb;
    cursor: pointer;
    i {
      line-height: 35px;
      font-size: 20px;
    }
  }
  .calendar__arrow--left {
    bottom: 48%;
    left: 0;
  }
  .calendar__arrow--right {
    bottom: 48%;
    right: 0;
  }
}