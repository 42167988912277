@import '../../scss/settings/mixins';

.login, .register {
  padding: 15px;
  color: #000;
  height: calc(100vh - 118px);
  background-color: #fff;
  .login__container, .register__container {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 2rem;
    top: 0;
    @include tablet() {
      top: 100px;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 50px;
      font-weight: 300;
    }
    .login__form, .register__form {
      width: 300px;
      label {
        margin-bottom: 3px;
      }
      .login__input, .register__input {
        margin-bottom: 5px;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      }
      .login__error-message, .register__error-message {
        color: red;
        font-size: 14px;
        margin-bottom: 15px;
      }
      .login__buttons, .register__buttons  {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        button {
          padding: 0.5rem 1rem;
          color: #000;
          border-color: #000;
          &:hover {
            color: #fff;
            background-color: #000;
        }
        }
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:active {
    -webkit-text-fill-color: #495057;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
}

.login__input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.login_container--top {
  top: 60px;
}
