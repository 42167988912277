@import '../../scss/settings/mixins';

.add-training__background {
  position: absolute;
  top: 0;
  z-index: -2;
  width: 100%;
  height: 140vh;
  background-image: url("../../assets/add-training-bgc.jpg");
  background-size: cover;
  background-position: center;
  @include tablet() {
    height: 105vh;
  }
}

.add-training__overlay {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 140vh;
  background-color: rgba(0, 0, 0, 0.6);
  @include tablet() {
    height: 105vh;
  }
}

.add-training__container {
  max-width: 500px;
  margin: 0 auto;

  .add-training__form {
    width: 100%;
    padding: 40px 20px;

    .add-training__form-group {
      margin-bottom: 1.15rem;
      width: 100%;
      @include tablet() {
        margin-bottom: 1.75rem;
      }

      label {
        color: #fff;
        font-weight: bold;
        margin-bottom: 3px;
      }

      input {
        color: #bd2130;
        height: calc(2em + .5rem + 2px);

        &:focus {
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem #2b91ff;
        }
      }

      ::placeholder {
        color: #000;
        opacity: 0.8;
      }
    }

    .note {
      margin-bottom: 40px;
      textarea {
        resize: none;
        color: #bd2130;
        &:focus {
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem #2b91ff;
        }
      }
    }
    .add-training--button {
      width: 100px;
      font-size: 13px;
      line-height: 35px;
      font-weight: 700;
      padding: 5px 0;
      border-radius: 20px;
      transition: 0.3s;
      border: none;
      margin-bottom: 45px;
      background-color: #efefef;
    }

    .reactSelect div:first-of-type {
      width: 100%;
    }

    .reactSelect {
      input {
        height: calc(1em + .5rem + 2px);
      }

      .css-1uccc91-singleValue {
        color: #bd2130;
      }
    }
    .add-training__error-message {
      color: red;
      font-size: 14px;
      margin-top: 5px;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:active {
    -webkit-text-fill-color: #bd2130;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    //-webkit-box-shadow: 0 0 0 0.2rem #bd21302b;
  }
}

.add-training__exercises-view-container {
  height: 300px;
  overflow-y: auto;
  padding: 0 0.75em;
  max-width: 650px;
  margin: 0 auto;
  @include tablet() {
    height: 350px;
  }

  .add-training__exerciseView {
    position: relative;
    margin-bottom: 5px;
    display: flex;
    max-width: 100%;
    border: 1px solid #fff;
    color: #000;
    font-weight: bold;
    padding: 0.6em 1em;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    &:hover {
      border: 1px solid #fff;
      color: #000;
      background-color: rgba(255, 255, 255, 0.8);
    }
    p {
      font-size: 15px;
      margin-right: 20px;

      &:first-of-type {
        text-transform: capitalize;
      }
      &:last-of-type {
        color: #bd2130;
        margin: 0;
        position: absolute;
        right: 20px;
      }
    }

    .add-training__exerciseView--reps {
    }

    .add-training__exerciseView--time {
    }

    .add-training__exerciseView--weight {
    }
  }
}



.add-training__buttons {
  display: flex;
  justify-content: space-between;
}

.add-training__result-container {
  margin: 2rem auto;
  max-width: 350px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 3px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1em 0.5em;
  @include tablet() {
    margin: 8rem auto;
    max-width: 500px;
  }
  .add-training__result-title {
    font-size: 22px;
    margin-bottom: 20px;
  }
  span, h3 {
    font-weight: bold;
  }
  h3 {
   margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
  .add-training__result-list {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    margin-bottom: 40px;
    .add-training__result-list--element {
      margin-bottom: 10px;
      .add-training__result-list--element-name {
        margin-right: 5px;
        text-transform: capitalize;
        font-weight: 400;
      }
      .add-training__result-list--element-rep {
        margin-right: 12px;
        font-weight: 600;
      }
    }

  }

}

