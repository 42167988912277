.big-six__buttons--desktop {
  button {
    margin-left: 10px;
  }
  .big-six__btn--logout {
    margin-left: 25px;
  }
}

.exercise__cover {
  position: relative;
  transition: 0.5s linear;
}

.big-six__cover {
  position: relative;
  top: 0;
  transition: 0.5s linear;
  .big-six {
    padding: 0.75rem 0.75rem 0;
    background-color: #fff;
    h2 {
      font-size: 28px;
      text-align: center;
      margin-top: 30px;
    }
    .big-six__description {
      max-width: 600px;
      margin: 20px auto;
      padding: 1rem;
      text-align: justify;
      text-align-last: left;
    }
    .big-six__container {
      border: 1px solid #6c757d;
      padding: 1rem;
      margin: 0.5rem auto 0.5rem;
      background-color: #beccd899;
      max-width: 800px;
      .row {
        margin-bottom: 20px;
        .big-six__exercise {
          font-weight: 600;
        }
        .col {
          line-height: 20px;
        }
      }
    }
  }
}


