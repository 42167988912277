@import '../../scss/settings/mixins';

.overlay {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}

.jumbotron {
  margin: 0;
}
.hero {
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/lp-bgc.jpg');
  background-size: cover;
  background-position: center;
  height: calc(100vh - 70px);

  h2 {
    color: #ff8324;
    text-align: center;
    font-size: 4rem;
    margin-bottom: 10px;
  }

  .hero__para {
    font-size: 18px;
    position: relative;
    z-index: 2;
    text-align: center;
    max-width: 420px;
    margin-bottom: 20px;

    p {
      line-height: 22px;
    }
  }

  .fa-arrow-down {
    position: absolute;
    z-index: 10;
    bottom: 20px;
    left: 50%;
    font-size: 40px;
    color: #fff;
    transform: translateX(-50%);
    animation: bounce 1.6s infinite;
    cursor: pointer;
  }
}

.home {
  padding: 1em;
  background-color: #fff;
  .home__feature {
    max-width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @include tablet() {
      margin: 100px auto;
    }
    .home__feature-heading {
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      line-height: 30px;
      h3 {
        text-align: center;
        font-size: 40px;
        margin-bottom: 25px;
        line-height: 45px;
        @include tablet() {
          text-align: left;
        }
      }
      p {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
        @include tablet() {
          text-align: left;
        }
      }
      button {
        border: 0;
        padding: 15px 10px;
        border-radius: 5px;
        background-color: #ccc;
        margin-bottom: 30px;
        align-self: center;
        max-width: 180px;
        @media (min-width: 900px) {
          align-self: flex-start;
        }
        &:hover {
          color: #fff;
          background-color: #626262;
        }
      }
    }
    .home__feature-img {
      max-width: 250px;
      max-height: 250px;
      @include tablet() {
        max-width: 350px;
        max-height: 350px;
      }
      img {
        max-width: 100%;
        margin-bottom: 30px;
      }
    }
  }
  hr {
    margin: 100px 0;
  }

}

//@keyframes scaleUp {
//  0% {
//    transform: scale(1);
//  }
//  50% {
//    transform: scale(1);
//  }
//  75% {
//    transform: scale(1.4);
//  }
//  100% {
//    transform: scale(1);
//  }
//}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
  }
}