.exercises {
  max-width: 1200px;
  margin: 40px auto;
  .exercises__header {
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px;
    margin-bottom: 5px;
    h3 {
      font-size: 20px;
      text-align: center;
      padding: 10px 20px;
      background-color: #434343;
      color: #fff;
      border-radius: 3px;
      text-transform: uppercase;
      font-weight: 600;
      min-width: 120px;
    }
    h2 {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
      margin-left: 30px;
      text-align: center;
    }
  }
  .exercises__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 5px;
    .exercises__image {
      min-width: 330px;
      height: 400px;
      background-size: cover;
      background-position: center;
      margin: 5px 5px;
      flex-grow: 1;
    }
  }
  .exercises__goals-container {
    display: flex;
    flex-direction: column;
    margin: 25px auto;
    max-width: 430px;
    padding: 0 10px;
    .exercises__goals-title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .exercises__goals-list {
      list-style-image: url("../../../assets/icons/push.svg");
      span {
        font-weight: bold;
      }
    }
  }
  .exercises__paginate-container {
    margin-top: 50px;
    .exercises__paginate-list {
      display: flex;
      justify-content: center;
      max-width: 500px;
      margin: 0 auto;
      li {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #6c757d;
        margin: 5px;
        cursor: pointer;
        &:hover {
          background-color: #ff8324;
          color: #e5e5e5;
        }
      }
    }
  }
}

.active {
  background-color: #ff8324;
  color: #e5e5e5;
}

