@import '../../../scss/settings/mixins';

.navigation-items {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  @include tablet() {
    flex-direction: row;
  }
  .navigation-item {
    margin: 0 0 0 45px;
    display: block;
    a {
      display: block;
      width: 100%;
      padding: 28px 0;
      font-size: 14px;
      letter-spacing: 1.5px;
      font-weight: 600;
      text-transform: uppercase;
      color: #a5a5a5;
      &:hover {
        color: #000;
        @include tablet() {
          color: #fff;
        }
      }
    }
    .active {
      color: #000;
      background-color: #fff;
      @include tablet() {
        color: #fff;
        background-color: #000;
      }
    }
  }
}

